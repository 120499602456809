/* Universal Styles */
:root {
    --primary-color: #000;
    --secondary-color: #fff
}

* {
    margin: 0 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

main {
    margin: 0 auto;
    max-width: 800px;
}

a {
    text-decoration: none;
    color: var(--primary-color)
}

a:hover {
    font-weight: bolder;
    color: var(--primary-color)
}

nav ul {
    display: flex;
    justify-content: center;
}

ul {
    list-style-type: none;
}

section {
    margin: 5%;
}

.section-title {
    border-bottom: 1px solid var(--primary-color);
    margin-bottom: 2.5%;
    padding-bottom: 8px;
}


/* header */
header {
    /* display: flex; */
    color: var(--primary-color);
    justify-content: center;
    margin: 20px;
    flex-wrap: wrap;
    background-color: var(--secondary-color);
    width: 100%
}

header h1 {
    display: flex;
    justify-content: center;
    width: 100%;
}

header nav ul {
    display: flex;
    padding: 0;
}

header nav ul li {
    margin: 20px;
}

header span {
    cursor: pointer;
}

.navActive span {
    font-weight: bold;
}

/* about */
.about {
    display: flex;
}

.about-me img {
    max-width: 250px;
    border-radius: 50%;
    height: 100%;
}

.about-text {
    margin-left: 20px;
}

/* projects */
.portfolio {
    max-width: 95%;
}

.projects {
    margin-bottom: 4%;
    display: flex;
    flex-wrap: wrap;
}

.feature-project {
    display: flex;
    margin-bottom: 1%;
    justify-content: space-between;
}

.feature-project .project {
    width: 49%;
}

.feature-project img {
    height: 300px;
    object-fit: cover;
}

.feature-project h3,
.feature-project p {
    margin: 0 auto;
}

.tertiary-projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 1%;
}

.tertiary-projects .project {
    width: 30%;
}

.tertiary-projects a {
    width: 100%;
    display: flex;
    justify-content: center;
}

.project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1% 0;
    width: 100%;
}

.project img {
    height: 200px;
    object-fit: cover;
}

.project h3,
.project p {
    margin: 0 auto;
}

/* resume */
#resume-link {
    font-weight: bold;
}

/* contact */
.contact-info {
    display: flex;
    justify-content: space-around;
}

.contact-info .casual a,
.contact-info .professional a,
.contact-info .professional p,
.contact-info .professional h3 {
    margin: 0 auto;
}

.casual,
.professional {
    display: flex;
    flex-direction: column;
}

.casual h3,
.professional h3 {
    text-align: center;
}


/* footer */
footer {
    background-color: var(--secondary-color);
    margin-top: 20px;
}

footer nav ul {
    display: flex;
    padding: 0;
    justify-content: center;
}

footer nav ul li {
    padding: 0 3% 3%;
}


/* Media Queries */
@media only screen and (max-width: 910px) {
    body {
        margin: 0 0;
    }


    /* Projects */
    .feature-project a img {
        width: 100%;
    }

    .project {
        width: 100%;
    }

    .proejct img {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .about {
        flex-direction: row;
    }
}

@media only screen and (max-width: 425px) {
    .about {
        justify-content: center;
        flex-wrap: wrap;
    }

    footer {
        margin-top: 3%;
    }


}